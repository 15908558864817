import React, { useState, useEffect, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { navigate } from "gatsby";
import { CircularProgress } from "@material-ui/core";
import { withFirebase } from "../../../utils/Firebase";
import { LANDING } from "../../../constants/routes";
import HorizontalBar from "../Report/atoms/HorizontalBar";
import Resources from "./atoms/Resources";
import countryData from "../../../data/countryData.json";
import MinistryMap from "./atoms/MinistryMap";
import ContactForm from "../../atoms/ContactForm";

const PublicMinistry = ({ firebase, ministryId }) => {
  console.log("[PublicMinistry]", ministryId);
  if (!ministryId && typeof window !== "undefined") {
    navigate(LANDING);
  }
  const [data, setData] = useState();
  const [tooltipContent, setTooltipContent] = useState();
  const [topojson, setTopojson] = useState();

  useEffect(() => {
    if (firebase && !data) {
      getMinistryData(ministryId);
    }
    if (!topojson && data) {
      firebase
        .topojson(data.country, data.country)
        .once("value")
        .then((snapshot) => {
          setTopojson(snapshot.val());
        })
        .catch((err) => console.log("topojson ERROR: ", err));
    }
  }, [firebase, data]);

  const getMinistryData = async (id) => {
    const data = await firebase
      .ministry(id)
      .get()
      .then((doc) => doc.data());
    setData(data);
  };

  const locationsVisited = useMemo(() => {
    if (data && data.reportData) {
      const {
        accepted,
        delivered,
        have,
        unfinished,
        need,
        rejected,
      } = data.reportData;
      const a = accepted ? +accepted : 0;
      const d = delivered ? +delivered : 0;
      const h = have ? +have : 0;
      const u = unfinished ? +unfinished : 0;
      const n = need ? +need : 0;
      const r = rejected ? +rejected : 0;
      return a + d + h + u + n + r;
    }
    return;
  }, [data]);

  const ministryLink = () => {
    if (data) {
      const split = data.ministryLink.split("//");
      return split[split.length - 1];
    }
    return "...";
  };

  const ministryDonationLink = () => {
    if (
      data &&
      data.donationLink &&
      data.donationLink !== "donations@sharebibles.com"
    ) {
      const split = data.donationLink.split("//");
      return split[split.length - 1];
    }
    return "";
  };

  const getCountryFlag = (cc) => {
    // Mild sanity check.
    if (cc.length !== 2) return cc;

    // Convert char to Regional Indicator Symbol Letter
    const risl = (chr) => {
      return String.fromCodePoint(
        0x1f1e6 - 65 + chr.toUpperCase().charCodeAt(0)
      );
    };

    // Create RISL sequence from country code.
    return risl(cc[0]) + risl(cc[1]);
  };

  return (
    <div>
      {!data ? (
        <div className="progressCircle">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="reportRegionTitle">
            <div className="regionTitle">{data.name}</div>
            <div className="ministryCountry">
              ({countryData[data.country].NAME_LONG} ~{" "}
              {getCountryFlag(countryData[data.country].ISO_A2)})
            </div>
            <div className="ministryDescription">{data.description}</div>

            <a href={`https://${ministryLink()}`}>
              <div className="ministryLink">{ministryLink().toLowerCase()}</div>
            </a>
            {ministryDonationLink() && (
              <div className="ministryDonationLink">
                <span className="donate">Donate:</span>
                <a href={`https://${ministryDonationLink()}`}>
                  {ministryDonationLink().toLowerCase()}
                </a>
              </div>
            )}
          </div>
          {!!data.reportData && (
            <>
              <div className="mapNTotalDiv">
                <div className="reportMapBackDiv">
                  <div
                    style={{
                      width: "100%",
                      maxWidth: "70vh",
                      maxHeight: "70vh",
                      border: "solid 5px #1F79BC",
                      backgroundColor: "white",
                      borderRadius: 15,
                      margin: 10,
                      minWidth: 300,
                    }}
                  >
                    {!topojson ? (
                      <div className="loadingMap">
                        <div className="progressCircle">
                          <CircularProgress />
                        </div>
                      </div>
                    ) : (
                      <>
                        <MinistryMap
                          topojson={topojson}
                          regions={
                            data.reportData && data.reportData.activeRegions
                              ? data.reportData.activeRegions
                              : []
                          }
                          setTooltipContent={setTooltipContent}
                        />
                        <ReactTooltip>{tooltipContent}</ReactTooltip>
                      </>
                    )}
                  </div>
                </div>
                {!!data && (
                  <div className="totalCountReport">
                    <p className="count">
                      <span className="countNumber">
                        {data.reportData.bibles.toLocaleString()}
                      </span>
                      <span className="countTextDiv">
                        <span className="countText">Bibles shared</span>
                      </span>
                    </p>
                    <div className="countDivider" />
                    <p className="count">
                      <span className="countNumber">
                        {locationsVisited.toLocaleString()}
                      </span>
                      <span className="countTextDiv">
                        <span className="countText">Locations visited</span>
                      </span>
                    </p>
                  </div>
                )}
              </div>
              {!!data.reportData.resources && (
                <div className="ministryResources">
                  <Resources resourcesData={data.reportData.resources} />
                </div>
              )}
              {!!data.reportData && (
                <div className="horizontalChart">
                  <HorizontalBar data={data.reportData} />
                </div>
              )}
            </>
          )}
          <div>
            <ContactForm
              receiverEmail={data.contactEmail}
              headerTitle={`Contact: ${data.name}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withFirebase(PublicMinistry);
