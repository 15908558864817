import React, { useState, useEffect } from "react";
import { withFirebase } from "../../../../utils/Firebase";

const Resources = ({ firebase, resourcesData }) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (firebase && !data) {
      getResources(resourcesData);
    }
  });

  const getResources = async (resData) => {
    const keys = Object.keys(resData);
    const resourceData = {};
    if (keys.length > 10) {
      const querySnapshot1 = await firebase
        .resources()
        .where("resourceId", "in", keys.slice(0, 9))
        .get();
      querySnapshot1.forEach((doc) => {
        resourceData[doc.id] = doc.data();
      });
      const querySnapshot2 = await firebase
        .resources()
        .where("resourceId", "in", keys.slice(10, 19))
        .get();
      querySnapshot2.forEach((doc) => {
        resourceData[doc.id] = doc.data();
      });
      if (keys.length > 20) {
        const querySnapshot3 = await firebase
          .resources()
          .where("resourceId", "in", keys.slice(20, 29))
          .get();
        querySnapshot3.forEach((doc) => {
          resourceData[doc.id] = doc.data();
        });
      }
      if (keys.length > 30) {
        const querySnapshot4 = await firebase
          .resources()
          .where("resourceId", "in", keys.slice(30, 39))
          .get();
        querySnapshot4.forEach((doc) => {
          resourceData[doc.id] = doc.data();
        });
      }
    } else {
      const querySnapshot = await firebase
        .resources()
        .where("resourceId", "in", keys)
        .get();
      querySnapshot.forEach((doc) => {
        resourceData[doc.id] = doc.data();
      });
    }
    setData(resourceData);
  };

  const sortResourcesByCount = (resObj) => {
    var sortable = [];
    for (var res in resObj) {
      sortable.push([res, resObj[res]]);
    }

    sortable.sort(function (a, b) {
      return a[1] - b[1];
    });
    return sortable;
  };

  return (
    <table className="resources__table">
      <tbody>
        <tr className="resources__table__row">
          <th className="resources__table__cell">Resources</th>
          <th className="resources__table__cell">Format</th>
          <th className="resources__table__cell">Language</th>
          <th className="resources__table__cell">Bibles Shared</th>
        </tr>
        {data &&
          sortResourcesByCount(resourcesData) &&
          sortResourcesByCount(resourcesData).map((m) => {
            return (
              <tr key={m[0]} className="resources__table__row">
                <td className="resources__table__cell__centerText">
                  <div>{data[m[0]] ? data[m[0]].name : "?"}</div>
                </td>
                <td className="resources__table__cell__centerText">
                  {data[m[0]] ? data[m[0]].format : "?"}
                </td>
                <td className="resources__table__cell__centerText">
                  {data[m[0]] ? data[m[0]].language.split("--")[1] : "?"}
                </td>
                <td className="resources__table__cell__centerText">
                  {m[1].toLocaleString()}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default withFirebase(Resources);
