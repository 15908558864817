import React from "react";
import Layout from "../utils/layout";
import PublicMinistry from "../components/scenes/PublicMinistry/PublicMinistry";

export default ({ location }) => {
  console.log("report.js LOCATION", location);
  const { state } = location;
  const ministryId = state ? state.ministryId : null;

  return (
    <Layout>
      <PublicMinistry ministryId={ministryId} />
    </Layout>
  );
};
